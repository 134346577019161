<template>
    <div class="h-150 col-span-4 sm:col-span-12 p-2 mb-4 rounded overflow-hidden">
        <div class="skeleton-loader rounded w-[23em] sm:w-[100%] mb-4"
        :class="selectionMoment === true ? 'h-[13rem]' : 'h-[15.5em]'"></div>

        <div class="flex items-center mb-3 gap-2 justify-between">
            <div class="skeleton-loader w-20 h-7 rounded"></div>
            <div class="skeleton-loader w-56 h-7 rounded"></div>
        </div>

        <div class="flex items-center mb-5 gap-2 justify-between">
            <div class="skeleton-loader w-3/6 h-6"></div>
            <div class="skeleton-loader w-2/6 h-6"></div>
        </div>

        <div class="skeleton-loader w-full h-16 mt-4 mb-2"></div>

        <div class="flex items-center mb-3 flex-wrap gap-2">
            <div class="skeleton-loader w-28 h-9 rounded"></div>
            <div class="skeleton-loader w-28 h-9 rounded"></div>
            <div class="skeleton-loader w-28 h-9 rounded"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SkeletonRecommande',
    props: {
        selectionMoment: {
            type: Boolean,
            default: false
        },
    }
}
</script>

<style scoped>

</style>
