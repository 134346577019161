<template>
    <div class="mx-auto h-full bg-forest-light rounded">
        <div class="relative h-full w-fit mx-auto" ref="map"></div>
    </div>
</template>

<script>
import * as d3 from "d3";
import json from '~/assets/departments.json';
import pinMap from '~/assets/pin_map.svg';

export default {
    name: "Map",
    props: {
        lat: {},
        lng: {}
    },
    data() {
        return {
            mapJson: json,
            pinMap: pinMap,
            latMin: 51.0521,
            lngMin: -4.4744,
            latMax: 42.1958,
            lngMax: 8.1350,
            flippedY: false
        }
    },
    mounted() {
        this.initMap();
    },
    methods: {
        initMap() {
            const width = 750, height = 550;
            const path = d3.geoPath();

            const projection = d3.geoConicConformal() // projection Lambert-93
                .center([2.454071, 46.279229]) // On centre sur la France
                .translate([width/2, height/2])
                .scale(3000) //on zoome;

            path.projection(projection);
            /* on crée l'objet svg */
            const svg = d3.create('svg')
                .style('max-width', '100%')
                .style('max-height', '100%')
                .attr("id", "svg")
                .attr('viewBox', [0, 0, width, height])
                .attr("width", width)
                .attr("height", height)
                .attr("fill", "white");

            const deps = svg.append("g");

            var pt = path.centroid({
                "type": "Feature",
                "properties": {},
                "geometry": {
                    "coordinates": [
                        this.lng,
                        this.lat
                    ],
                    "type": "Point"
                }
            });

            // Red dot for debugging only
            // svg.append("g").attr('fill', 'red')
            //     .append("circle")
            //     .attr("cx", pt[0])
            //     .attr("cy", pt[1])
            //     .attr("r", 4);

            /* on crée la map
            */
            deps.selectAll("path")
                .data(this.mapJson.features)
                .enter()
                .append("path")
                .style("stroke", "white")
                .attr("d", path)

            const m = d3.xml(this.pinMap).then(data => {
                const elem = data.documentElement;
                elem.getElementsByTagName("g")[0].setAttribute("transform", `translate(${pt[0]} ${pt[1]}) scale(1.75) ${pt[1] < 80 ? ' rotate(180)' : ''}`);
                elem.querySelector("g #shape").setAttribute("transform", `translate(-23 -50)`);

                let dropShadow = 'filter: drop-shadow(0px 4px 3px rgba(0, 0, 0, .3));';
                if (pt[1] < 80) {
                    elem.querySelector("g #m")
                        .setAttribute("transform", `translate(46 37) rotate(180)`);
                    dropShadow = 'filter: drop-shadow(0px -4px 3px rgba(0, 0, 0, .3));';
                }

                elem.querySelector("g #shape").setAttribute("style", dropShadow);

                svg.node().append(elem.getElementsByTagName("g")[0]);
                svg.node().append(elem.getElementsByTagName("defs")[0]);
            });

            this.$refs.map.appendChild(svg.node());
        },
    }
}
</script>

<style scoped lang="scss">

</style>
