<template>
    <div class="max-w-container md:max-w-md relative sm:max-w-sm w-full" :id="id">
        <div class="overflow-x-hidden">
            <div ref="frise" class="content-container flex transition-all duration-1000 h-full gap-2 sm:gap-0 w-full"
                 :class="classesTransition"
                 :style="valueTranslate"
            >
                <slot v-bind:currentIndex="currentIndexEtape"></slot>
            </div>
        </div>

        <div v-if="showArrows" class="container-fleches flex justify-center items-center my-4">
            <div class="mr-6 w-14 h-14 cursor-pointer select-none border border-black rounded-full flex align-center items-center justify-center" @click="move(-1)">
                <icons-arrow-narrow-left-solid class="w-5 h-5"></icons-arrow-narrow-left-solid>
            </div>

            <div class="w-14 h-14 cursor-pointer select-none border border-black rounded-full flex align-center items-center justify-center" @click="move(1)">
                <icons-arrow-narrow-right-solid class="w-5 h-5"></icons-arrow-narrow-right-solid>
            </div>
        </div>

        <div v-if="showArrowsWithDot || showDot" class="flex justify-center items-center">
            <div v-if="showArrowsWithDot" class="flex w-14 h-14 cursor-pointer rounded-full align-center items-center justify-center"
                 @click="move(-1)" :style="{color: currentIndexEtape > 0 ? colorSelected : '#E0D9D7'}"
            >
                <icons-arrow-narrow-left-outline class="w-7"></icons-arrow-narrow-left-outline>
            </div>

            <div class="flex gap-x-2">
                <div v-for="index in (customLast ? indexEnd + 1 : indexEnd) + 1" @click.prevent.stop="currentIndexEtape = (index-1)">
                    <p v-if="index !== currentIndexEtape + 1" class="rounded-full w-3 h-3 border border-grey4" :style="{background: bgColor}"></p>

                    <div v-else class="w-3 h-3 rounded-full" :style="{background: colorSelected}"></div>
                </div>
            </div>

            <div v-if="showArrowsWithDot" class="w-14 h-14 rounded-full flex align-center items-center justify-center"
                 @click="move(1)" :style="{color: currentIndexEtape < (customLast ? indexEnd + 1 : indexEnd) ? colorSelected : '#E0D9D7'}"
            >
                <icons-arrow-narrow-right-outline class="w-7"></icons-arrow-narrow-right-outline>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Frise",
    props: {
        indexEnd: {
            type: Number,
            required: true
        },
        indexStart: {
            type: Number,
            required: true
        },
        showArrows: {
            type: Boolean,
            required: false,
            default: false
        },
        showArrowsWithDot: {
            type: Boolean,
            required: false,
            default: false
        },
        showDot: {
            type: Boolean,
            required: false,
            default: false
        },
        id: {
            type: String,
            required: false,
            default: "default"
        },
        swippable: {
            type: Boolean,
            required: false,
            default: true
        },
        coefGap: {
            type: Number,
            required: false,
            default: 0.0625
        },
        colorSelected: {
            type: String,
            required: false,
            default: '#F56E58'
        },
        bgColor: {
            type: String,
            required: false,
            default: '#DBD6D5'
        },
        customLast: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    data() {
        return {
            currentIndexEtape: this.indexStart,
            classesTransition: [],
            windowEl: null,
            xTouch:null
        }
    },
    mounted() {
        if (process.client && this.swippable) {
            let element = document.getElementById(this.id);

            if (element != null) {
                element.addEventListener("touchstart", this.handleTouchStart, false);
                element.addEventListener("touchend", this.handleTouchEnd, false);
            }
        }
    },
    methods: {
        move(direction) {
            this.classesTransition = [];

            let newCurrentIndex = this.currentIndexEtape + direction;

            if (newCurrentIndex < 0) {
                newCurrentIndex = 0;
            } else if (newCurrentIndex > (this.customLast ? this.indexEnd + 1 : this.indexEnd)) {
                newCurrentIndex = this.customLast ? this.indexEnd + 1 : this.indexEnd;
            }

            this.currentIndexEtape = newCurrentIndex;
        },
        calculateWidth(){
            return this.$refs.frise ? this.coefGap * this.$refs.frise.children[0]?.clientWidth : 21.5;
        },
        handleTouchStart(evt) {
            this.xTouch = evt.touches[0].clientX;
        },
        handleTouchEnd(evt) {
            if (!this.xTouch) {
                return;
            }
            const xDiff = this.xTouch - evt.changedTouches[0].clientX;

            if (xDiff > 50) {
                if (this.currentIndexEtape !== (this.customLast ? this.indexEnd + 1 : this.indexEnd)) {
                    this.move(1);
                }
            } else if (xDiff < -50) {
                if (this.currentIndexEtape !== this.indexStart) {
                    this.move(-1);
                }
            }

            this.xTouch = null;
        }
    },
    computed: {
        valueTranslate() {
            return {
                "transform": "translateX(-" + this.currentIndexEtape * this.calculateWidth() + "rem)"
            }
        }
    }
}
</script>

<style scoped lang="scss"></style>
