<template>
    <article
        class="col-span-4 sm:col-span-12 p-2 mb-4 rounded-lg group relative flex flex-col justify-between"
        :class="{'opacity-50': option, 'hover:bg-forest-light2': selectionMoment === false || (selectionMoment === true && isHome === true), 'hover:bg-forest-light': selectionMoment === true && isHome === false}"
    >
        <div v-if="bien">
            <div v-if="option"
                 class="absolute top-6 left-6 flex items-center text-tangerine bg-tangerine-light text-body-14 z-[1] p-3 rounded"
            >
                <icons-ban-outline class="w-5 mr-1"></icons-ban-outline>
                Indisponible - Réservation en cours
            </div>

            <div class="relative mb-4">
                <div class="hidden group-hover:block rounded w-fit h-[15.5em] sm:w-[100%] object-cover">
                    <Map
                        :lat="getLatLng('lat')"
                        :lng="getLatLng('lng')"
                    />
                </div>

                <div
                    v-if="bien.etiquettes != null && bien.etiquettes.length > 0"
                    class="absolute top left text-white px-2 py-1.5 rounded-tl group-hover:hidden bg-blue"
                    :class="colorBgEtiquette(bien.etiquettes)"
                >
                    {{ etiquetteText(bien.etiquettes) }}
                </div>

                <div v-if="showBanniere" class="absolute group-hover:hidden h-[15.5em] w-full sm:w-[100%]">
                    <div class="relative h-full w-full">
                        <div :style="banniereStyle()" class="absolute overflow-hidden">
                            <template v-for="(element, i) in banniere.elements">
                                <div v-if="banniere.elements[i].type === 'Texte'"
                                     :style="elementTexteStyle(i)"
                                     class="absolute leading-3 whitespace-nowrap">
                                    {{ banniere.elements[i].value }}
                                </div>

                                <icone-dynamique-composant
                                    v-if="banniere.elements[i].type === 'Icone'"
                                    :type="banniere.elements[i].value"
                                    :style="elementIconStyle(i)" class="absolute">
                                </icone-dynamique-composant>
                            </template>
                        </div>
                    </div>
                </div>

                <nuxt-img
                    ref="imgBien"
                    format="webp"
                    densities="1x"
                    quality="80"
                    sizes="lg:500px"
                    :src="srcImage"
                    class="rounded w-[23em] h-[15.5em] sm:w-[100%] object-cover group-hover:hidden"
                    :alt="titre"
                    @error="endLoadError"
                    @load="endLoad">
                </nuxt-img>
            </div>

            <div class="flex items-center justify-between h-7 mb-3">
                <a class="flex items-center h-full group/note"
                   :class="{'!z-0': showSkeleton === true}"
                   target="_blank"
                   :href="runtimeConfig.blogUrl + '/loffre-maslow-en-detail/le-score-maslow-le-calcul-de-notre-indice-de-confiance'">
                    <MSeul class="text-forest w-5 h-6 group-hover/note:text-tangerine"></Mseul>

                    <div
                        class="ml-2 pl-3 pr-3 rounded-2xl text-forest group-hover/note:bg-tangerine group-hover/note:text-white font-medium"
                        :class="{'bg-forest-light': !selectionMoment, 'bg-white': selectionMoment}"
                    >
                        {{ frenchizer(score) }}/5
                    </div>
                </a>

                <catalogue-bien-type-invest :type-invest="typeInvest"></catalogue-bien-type-invest>
            </div>

            <div class="flex items-baseline justify-between h-7 mb-3">
                <div class="text-forest font-semibold">
                    {{ bien.commune }}
                    <span class="font-normal">({{ bien.code_postal.slice(0, 2) }})</span>
                </div>

                <div class="text-lg font-semibold">
                    <!-- askip le prix est correct car le calcul se fait côté Téthys-->
                    {{ price(bien.prix, 0, 0) }} €
                </div>
            </div>

            <div class="text-lg font-medium mb-1">
                {{ titre }}
            </div>
        </div>

        <catalogue-bien-specs :bien="bien" :selection-moment="selectionMoment" :dpe="dpe.value"></catalogue-bien-specs>

        <NuxtLink
            :to="{name: 'catalogue-slug-id', params: {slug: 'investissement-locatif-' + props.bien.commune.replace(/ /g, '-'), id: props.bien.slug}}"
            class="visually-hidden" v-if="bien != null && !option">
        </NuxtLink>

        <SkeletonRecommande v-if="showSkeleton === true && bien != null" class="absolute inset-0 h-full"
                            :class="[selectionMoment === false ? 'bg-white' : 'bg-forest-light2']"
                            :selectionMoment="true"
        >
        </SkeletonRecommande>
    </article>
</template>

<script setup>
import MSeul from "~/components/logos/MSeul.vue";
import Map from "~/pages/catalogue/components/Map.vue";

import {toRef, ref} from 'vue';
import SkeletonRecommande from "~/pages/catalogue/[slug]/components/SkeletonRecommande.vue";

const runtimeConfig = useRuntimeConfig().public;
const moment = useNuxtApp().$moment;

const props = defineProps({
    bien: {
        type: Object,
        required: true
    },
    selectionMoment: {
        type: Boolean,
        default: false
    },
    isHome: {
        type: Boolean,
        default: false
    },
    order: {
        type: Object
    }
});

const showBanniere = ref(false);
const errorImage = ref(false);
const showSkeleton = ref(true);
const banniere = toRef(props.bien, 'banniere');
const imgBien = ref(null);
const loadImgInterval = ref({});

const isMobile = ref(useNuxtApp().$isMobile);
const isTablet = ref(useNuxtApp().$isTablette);

function hoursCount(date) {
    return moment().diff(moment(date ?? undefined), 'hours');
}

onMounted(() => {
    if (banniere.value && banniere.value.style !== null && Object.keys(banniere.value.style).length > 0) {
        showBanniere.value = hoursCount(props.bien.datePromotionFin ?? undefined) <= 0 && hoursCount(props.bien.datePromotionDebut ?? undefined) >= 0;
    }

    loadImgInterval.value = setInterval(() => {
        if (imgBien.value?.$ !== null && imgBien.value?.$ !== undefined && imgBien.value?.$.isMounted) {
            endLoad();
        }
    }, 50);

    setTimeout(() => {
        clearInterval(loadImgInterval.value);
        endLoad();
    }, 1000);
});

onUnmounted(() => {
    clearInterval(loadImgInterval.value);
});

const score = computed(() => {
    let modifiedScore = (props.bien.score / 4).toPrecision(2);

    return modifiedScore > 4.9 ? 4.9 : (modifiedScore < 2.5 ? 2.5 : modifiedScore);
});

const isPinel = computed(() => {
    if (props.bien.fiscalites.includes('pinel') || props.bien.fiscalites.includes('pinel-plus')) {
        return true;
    } else if (Array.isArray(props.bien.fiscalites)) {
        return props.bien.fiscalites.some(fisca => fisca.slug.includes('pinel'));
    }

    return false;
});

const option = computed(() => {
    if (props.bien) {
        return props.bien.optionne === true;
    }

    return false;
});

const dpe = computed(() => {
    if (props.bien.dpe !== null) {
        return {value: props.bien.dpe};
    }

    return {};
});

const titre = computed(() => processShortCode(
    props.bien.titreBien,
    {
        ...props.bien,
        pinel: isPinel.value
    })
);

const typeInvest = computed(() => {
        if (isPinel.value === true) {
            return {label: "Éligible vide ou meublé", icon: "KeyOutline"};
        } else {
            return {label: "Meublé", icon: "CouchOutline"};
        }
    }
);

function banniereStyle() {
    return {
        left: banniere.value.style.leftPercent + '%',
        top: (isTablet.value ? 1.03 : 1) * banniere.value.style.topPercent + '%',
        width: banniere.value.style.widthPercent + '%',
        height: banniere.value.style.heightPercent + '%',
        background: banniere.value.style.background,
        borderTopLeftRadius: banniere.value.style.cornerTopLeft,
        borderTopRightRadius: banniere.value.style.cornerTopRight,
        borderBottomRightRadius: banniere.value.style.cornerBottomRight,
        borderBottomLeftRadius: banniere.value.style.cornerBottomLeft,
    };
}

function elementTexteStyle(i) {
    return {
        top: banniere.value.elements[i].style.position.top + '%',
        left: banniere.value.elements[i].style.position.left + '%',
        color: banniere.value.elements[i].style.color,
        fontSize: (isTablet.value ? 0.65 : (isMobile.value ? 0.87 : 1)) * banniere.value.elements[i].style.size + '%',
        fontWeight: banniere.value.elements[i].style.weight,
        letterSpacing: banniere.value.elements[i].style.letterSpacing + 'em',
        background: banniere.value.elements[i].style.haveBackground ? banniere.value.elements[i].style.background : null,
        paddingRight: banniere.value.elements[i].style.position.padding.right + '%',
        paddingTop: banniere.value.elements[i].style.position.padding.top + '%',
        paddingLeft: banniere.value.elements[i].style.position.padding.left + '%',
        paddingBottom: banniere.value.elements[i].style.position.padding.bottom + '%',
        fontFamily: banniere.value.elements[i].style.berlingske ? 'Berlingske' : '',
        borderRadius: banniere.value.elements[i].style.rounded ? banniere.value.elements[i].style.round + '%' : null,
        transform: 'rotate(' + -1 * banniere.value.elements[i].style.rotation + 'deg)',
    };
}

function elementIconStyle(i) {
    return {
        width: (isTablet.value ? 0.75 : 1) * banniere.value.elements[i].style.size + 'em',
        height: (isTablet.value ? 0.75 : 1) * banniere.value.elements[i].style.size + 'em',
        color: banniere.value.elements[i].style.color,
        top: (isTablet.value ? 1.05 : 1) * banniere.value.elements[i].style.position.top + '%',
        left: banniere.value.elements[i].style.position.left + '%',
        transform: 'rotate(' + -1 * banniere.value.elements[i].style.rotation + 'deg)'
    };
}

function getLatLng(str) {
    let centre = props.bien?.centre;

    if (centre !== null && centre.includes("POINT")) {
        return Number.parseFloat(/POINT\(([-0-9.]*) ([-0-9.]*)\)/.exec(centre)[str === "lat" ? 2 : 1]);
    }

    return null;
}

function endLoad() {
    showSkeleton.value = false;
}

function endLoadError() {
    errorImage.value = true;
    endLoad();
}

const srcImage = computed(() => {
    if (errorImage.value === false) {
        let order = 0;
        if(props.order?.hasOwnProperty(props.bien.pid)) {
            order = props.order[props.bien.pid].findIndex((e) => e === props.bien.slug)
        }

        return `${runtimeConfig.baseUrl}/catalogue/perspective/${props.bien.pid}/${order}`
    } else {
        return '/images/details-bien/img_bien.png';
    }
})
</script>

<style lang="scss" scoped>
article { //on a le a superposé sur tous les élements, ça casse le dom de mettre un a dans un a
    position: relative;

    a {
        position: relative;
        z-index: 1;
    }

    .visually-hidden {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;
        text-indent: 100%;
        white-space: nowrap;
        position: absolute;
        z-index: 0;
    }
}
</style>
